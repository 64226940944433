import Web3 from 'web3'
import store from '@/store'
import config from '@/config/app.config.js' // 引入配置
import factroyAbi from '../static/abi/factory.json'

let web3
try {
  web3 = new Web3(window.web3.currentProvider)
  // web3 = new Web3(new Web3.providers.HttpProvider('https://data-seed-prebsc-1-s1.binance.org:8545'))
} catch {
  web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed1.ninicoin.io'))
}

const factoryAddress = config.factoryAddress
const currentAccount = store.state.myAcount // 当前钱包账户
const contractFactory = new web3.eth.Contract(factroyAbi, factoryAddress)

// 统一错误返回
const failResult = (message) => ({ success: false, message })
// 统一成功返回
const successResult = (result) => ({ success: true, result })
/** ****    pancake router合约方法      ******/
/**
 * 获取token的pair
 * @address tokenA
 * @address tokenB
 * @return pair
 */
const getPair = async (tokenA, tokenB) => {
  try {
    const lpPair = await contractFactory.methods.getPair(tokenA, tokenB).call({ from: currentAccount })
    return successResult(lpPair)
  } catch (error) {
    return failResult(error)
  }
}
/** ****    pancake router合约方法      ******/

/** ****    lp合约方法      ******/
/**
 * 获取lp的两个token数量
 */
const getReserves = async (lpABI, lpAddress) => {
  const lpContract = await new web3.eth.Contract(lpABI, lpAddress)
  try {
    const resp = await lpContract.methods.getReserves().call({ from: store.state.myAcount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}
/**
 * 获取lp中的token0
 */
const getToken0 = async (lpABI, lpAddress) => {
  const lpContract = await new web3.eth.Contract(lpABI, lpAddress)
  try {
    const resp = await lpContract.methods.token0().call({ from: store.state.myAcount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 获取lp中的token1
 */
const getToken1 = async (lpABI, lpAddress) => {
  const lpContract = await new web3.eth.Contract(lpABI, lpAddress)
  try {
    const resp = await lpContract.methods.token1().call({ from: store.state.myAcount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}
/** ****    lp合约方法      ******/

const pancekeFactory = {
  contractFactory,
  getPair,
  getReserves,
  getToken0,
  getToken1
}

export default pancekeFactory
