<template>
  <div class="tab-page">
    <BackTop></BackTop>
    <div class="warehouse-wrap">
      <div class="warehouse-top" v-show="!operation">
        <div class="store-view">
          <div class="balance-name">MEB</div>
          <div class="pay-text">
            <span class="name">{{ balance }}</span>
            <span class="rmb"
              >≈ $
              <span v-if="mebPriceLoading">{{ mebTotal }}</span>
              <VanLoading v-else size="12" color="#818086" />
            </span>
          </div>
        </div>
        <div class="btn-view">
          <div class="wallte-btn" @click="handleToWithdraw">
            <span>{{ wareHouseInfo.withdrawal }}</span>
          </div>
        </div>
        <div class="wallet-tips" v-if="false">{{ ttps[mbLang] }}</div>
        <div class="withdrawal-view">{{ wareHourseNotice }}</div>
        <!-- <div class="remark">{{wareHouseInfo.notice}}</div> -->
        <div class="store-view" v-if="false">
          <div class="balance-name">
            <div>{{ wareHouseInfo.point }}(MEB)</div>
            <div class="detail" @click="handleToDetail">
              {{ wareHouseInfo.detail }}
            </div>
          </div>
          <div class="pay-text">
            <span class="name">{{ nodeBalance }}</span>
            <span class="rmb"
              >≈ $
              <span v-if="mebPriceLoading">{{ nodeTotal }}</span>
              <VanLoading v-else size="12" color="#818086" />
            </span>
          </div>
        </div>
      </div>
      <div class="warehouse-records">
        <div class="name" v-if="!operation">{{ wareHouseInfo.records }}</div>
        <div class="name" v-else>{{ wareHouseInfo.nodeDetail }}</div>
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          loading-text=" "
          loosing-text=" "
          pulling-text=" "
        >
          <van-list
            v-model="isLoading"
            @load="onReachBottom"
            :offset="0"
            :finished="finished"
            loading-text=" "
          >
            <div class="warehouse-list">
              <div class="staking-table">
                <div
                  class="table-title"
                  v-for="(item, index) in storeNameList"
                  :key="index"
                >
                  <span v-if="item.value === 0" class="number">{{
                    wareHouseInfo.number
                  }}</span>
                  <span v-if="item.value === 1">{{ wareHouseInfo.date }}</span>
                  <span v-if="item.value === 2 && !operation">{{
                    wareHouseInfo.operation
                  }}</span>
                </div>
              </div>
              <template v-if="!listLoading">
                <div
                  class="value-list"
                  v-for="(item, index) in walletList"
                  :key="index"
                >
                  <div class="value-item number">{{ item.number }}</div>
                  <div class="value-item createTime">{{ item.createTime }}</div>
                  <div
                    class="value-item operation"
                    style="color: #24c294"
                    v-if="item.operation === 1 && !operation"
                  >
                    {{ wareHouseInfo.status1 }}
                  </div>
                  <div
                    class="value-item operation"
                    style="color: #24c294"
                    v-else-if="item.operation === 2 && !operation"
                  >
                    {{ wareHouseInfo.status2 }}
                  </div>
                  <div
                    class="value-item operation"
                    style="color: #c1c1c1"
                    v-else-if="item.operation === 3 && !operation"
                  >
                    {{ wareHouseInfo.status3 }}
                  </div>
                  <div
                    class="value-item operation"
                    style="color: #40a9ff"
                    v-else-if="item.operation === 4 && !operation"
                  >
                    {{ wareHouseInfo.status4 }}
                  </div>
                  <div
                    class="value-item operation"
                    style="color: #ed8080"
                    v-else-if="item.operation === 5 && !operation"
                  >
                    {{ wareHouseInfo.status5 }}
                  </div>
                  <div
                    class="value-item operation"
                    style="color: #818086"
                    v-else-if="item.operation === 6 && !operation"
                  >
                    {{ wareHouseInfo.status6 }}
                  </div>
                  <div
                    class="value-item operation"
                    style="color: #ed8080"
                    v-else-if="item.operation === 7 && !operation"
                  >
                    {{ wareHouseInfo.status7 }}
                  </div>
                  <div
                    class="value-item operation"
                    style="color: #24c294"
                    v-else-if="item.operation === 8 && !operation"
                  >
                    {{ wareHouseInfo.status8 }}
                  </div>
                  <div
                    class="value-item operation"
                    style="color: #24c294"
                    v-else-if="item.operation === 9 && !operation"
                  >
                    {{ wareHouseInfo.status9 }}
                  </div>
                  <div
                    class="value-item operation"
                    style="color: #c1c1c1"
                    v-else-if="item.operation === 10 && !operation"
                  >
                    {{ wareHouseInfo.status10 }}
                  </div>
                  <div
                    class="value-item operation"
                    style="color: #24c294"
                    v-else-if="item.operation === 11 && !operation"
                  >
                    {{ wareHouseInfo.status11 }}
                  </div>
                  <div
                    class="value-item operation"
                    style="color: #24c294"
                    v-else-if="item.operation === 12 && !operation"
                  >
                    {{ wareHouseInfo.status12 }}
                  </div>
                  <div
                    class="value-item operation"
                    style="color: #24c294"
                    v-else-if="item.operation === 13 && !operation"
                  >
                    {{ wareHouseInfo.status13 }}
                  </div>
                  <div
                    class="value-item operation"
                    style="color: #c1c1c1"
                    v-else-if="item.operation === 14 && !operation"
                  >
                    {{ wareHouseInfo.status14 }}
                  </div>
                   <div
                    class="value-item operation"
                    style="color: #24c294"
                    v-else-if="(item.operation === 15 || item.operation === 16) && !operation"
                  >
                    {{ wareHouseInfo.status15 }}
                  </div>
                </div>
              </template>
              <van-loading
                v-else
                size="48"
                color="#66eab9"
                style="text-align: center"
              />
            </div>
            <!-- <div class="icon">
            <img src="../../static/img/gameGuild/arrow_left.png" @click="back" alt="">
            <img src="../../static/img/gameGuild/arrow_right.png" @click="next" alt="">
          </div> -->
          </van-list>
          <div class="notice-all" v-show="total === walletList.length">
            {{ wareHouseInfo.allLoad }}
          </div>
        </van-pull-refresh>
      </div>
      <van-dialog
        v-model="takeShow"
        class="dialog"
        show-cancel-button
        cancelButtonColor="#c1c1c1"
        confirmButtonColor="#ed8080"
        @confirm="comfirmTake"
        @cancel="cancelTake"
        :confirm-button-text="wareHouseInfo.confirm"
        :cancel-button-text="wareHouseInfo.cancel"
      >
        <div class="dialog-title">
          <div class="title">{{ wareHouseInfo.takeOut }}</div>
          <div class="content">
            <div>{{ takeOutNotice }}</div>
            <!-- <div class="botton-group">
              <div class="cancel" @click="cancelTake">{{wareHouseInfo.cancel}}</div>
              <div class="confirm" @click="comfirmTake">{{wareHouseInfo.confirm}}</div>
            </div> -->
          </div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import BackTop from '@/components/common/BackTop.vue'
import { mapState } from 'vuex'
import pancekeFactory from '@/utils/factory' // factory
import USDTGEPABI from '@/static/abi/lpToken' // usdt-gep的abi文件
import config from '@/config/app.config'
import { queryWarehouseList, queryWarehouseExtraction, queryLpValue, queryBalanceInfo } from '../../services/mining'
const contractsInfo = config.contractsInfo

export default {
  components: {
    BackTop
  },
  data () {
    return {
      listLoading: false,
      operation: '',
      finished: false, // 列表结束下拉加载
      refreshing: false, // 下拉刷新状态
      isLoading: false, // 下拉刷新状态
      pageNo: 1,
      pageSize: 25,
      total: 0,
      nodeBalance: 0,
      nodeTotal: 0,
      extractionCost: 0,
      maxWithdrawAmountEach: 0,
      walletList: [],
      balance: 0,
      lpValue: 0,
      titleText: '仓库',
      storeNameList: [
        { title: '数量', value: 0 },
        { title: '时间', value: 1 },
        { title: '操作', value: 2 }
      ],
      tokenGep: contractsInfo.tokenGEPContract,
      tokenUsdt: contractsInfo.tokenUSDTContract,
      mebPrice: '', // meb的单价
      mebPriceLoading: false,
      mebTotal: 0,
      ttps: {
        ZH: '*因铸币合约限制，DAPP暂时无币支持提币，将在12月6日16:00开放提币',
        EN: '*Due to the mint contract restrictions, DAPP will temporarily no coin support for coins, will be open on December 6 at 16:00.'
      },
      takeShow: false
    }
  },
  methods: {
    handleToWithdraw () {
      if (!this.balance) return
      if (this.withdrawFlag) return this.$toast(this.withdrawTips)
      this.takeShow = true
    },
    cancelTake () {
      this.takeShow = false
    },
    async comfirmTake () {
      this.withdrawFlag = true
      setTimeout(() => {
        this.withdrawFlag = false
      }, 60000)
      const resp = await queryWarehouseExtraction()
      if (!resp.success) {
        this.withdrawFlag = false
        return
      }
      this.takeShow = false
      this.getBalance()
      this.walletList = []
      this.pageNo = 1
      this.getWalletList()
    },
    onRefresh () {
      this.listLoading = true
      this.refreshing = true
      this.pageNo = 1
      this.walletList = []
      this.getWalletList()
      this.refreshing = false
      this.finished = false
    },
    onReachBottom () {
      this.isLoading = true
      if (this.pageNo * this.pageSize < this.total) {
        this.pageNo++
        this.getWalletList()
      } else {
        this.isLoading = false
      }
    },
    async getLpValue () {
      const resp = await queryLpValue()
      if (!resp.success) return
      this.lpValue = this.$gbUtils.formatTwoBalance(this.balance * resp.result)
    },
    async getBalance () {
      const resp = await queryBalanceInfo()
      if (!resp.success) return
      this.balance = resp.result.amountTotal
      this.nodeBalance = resp.result.nodeRewardTotal
      this.extractionCost = resp.result.extractionCost
      this.maxWithdrawAmountEach = resp.result.maxWithdrawAmountEach
    },
    async getWalletList () {
      const resp = await queryWarehouseList({
        operation: this.operation,
        pageSize: this.pageSize,
        pageNumber: this.pageNo
      })
      this.listLoading = false
      this.finished = true
      if (!resp.success) {
        this.isLoading = false
        return
      }
      resp.result.records.forEach(item => {
        item.createTime = item.createTime.replaceAll('-', '.')
        item.createTime = item.createTime.slice(5, 30)
        item.createTime = (item.createTime || '').split(' ')[1]
      })
      this.walletList.push(...resp.result.records)
      this.total = resp.result.total
      this.isLoading = false
    },
    // 获取lp对应的两个值量
    async getTokenAmount () {
      this.mebPriceLoading = false
      const resp = await pancekeFactory.getPair(
        this.tokenUsdt.address,
        this.tokenGep.address
      )
      if (!resp.success) return
      const lpAddress = resp.result
      const tokenresp = await pancekeFactory.getReserves(USDTGEPABI, lpAddress)
      if (!tokenresp.success) return
      const addressResp = await pancekeFactory.getToken0(USDTGEPABI, lpAddress)
      if (!addressResp.success) return
      var tokenAmount = Object.assign({ ...tokenresp.result })
      // toUpperCase()：将字符串str中的字符都转换为大写。
      // toLowerCase()：将字符串str中的字符都转换为小写。
      const address0 = addressResp.result.toUpperCase()
      const usdt = this.tokenUsdt.address.toUpperCase()
      if (address0 !== usdt) {
        // 位置不同，进行调换（usdt0 meb1）
        tokenAmount._reserve0 = tokenresp.result._reserve1
        tokenAmount._reserve1 = tokenresp.result._reserve0
      }
      // 获取meb的单价
      this.getMebPrice(tokenAmount)
    },
    // 获取meb的单价
    async getMebPrice (result) {
      const _reserve0 = result._reserve0 // usdt的流通量
      const _reserve1 = result._reserve1 // meb的值
      // console.log(_reserve0, _reserve1)
      // usdt对用的meb值 1 usdt = resp meb （_reserve0 / _reserve1)
      // const resp = await mebloxWeb3.quote(1, _reserve0, _reserve1)
      // meb的价值 1 meb = resp usdt
      const resp = await this.$web3.quote(1, _reserve1, _reserve0)
      this.mebPriceLoading = true
      if (!resp.success) return
      this.mebPrice = resp.result
      // console.log('this.mebPrice', this.mebPrice)
      this.mebTotal = this.$gbUtils.formatTwoBalance(this.balance * this.mebPrice)
      this.nodeTotal = this.$gbUtils.formatTwoBalance(this.nodeBalance * this.mebPrice)
    },
    handleToDetail () {
      this.pageNo = 1
      this.operation = 2
      this.storeNameList.length = 2
      this.walletList = []
      this.getWalletList()
    }
  },
  computed: {
    ...mapState(['mbLang']),
    wareHouseInfo () {
      return this.$t('wareHouse.wareHouseInfo')
    },
    wareHourseNotice () {
      return this.$t('wareHouse.wareHouseInfo.withdarwalNotice', { extractionCost: this.extractionCost })
    },
    takeOutNotice () {
      return this.$t('wareHouse.wareHouseInfo.takeOutNotice', { maxWithdrawAmountEach: this.maxWithdrawAmountEach })
    },
    withdrawTips () {
      return this.$t('common.withdrawTips')
    }
  },
  mounted () {
    this.listLoading = true
    this.getWalletList()
    this.getBalance()
    this.getTokenAmount() // 获取meb的单价
  },
  watch: {
    balance (val) {
      this.getTokenAmount()
    }
  }
}
</script>

<style lang="scss" scoped>
.wallet-tips {
  padding: 20px;
  box-sizing: border-box;
  font-size: 12px;
  color: #a2a2a2;
}
.tab-page {
  padding-top: 1px;
}
.warehouse-wrap {
  width: 93%;
  // min-height: 100vh;
  padding-bottom: 30px;
  box-sizing: border-box;
  background-size: 100% 100%;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 0 20px;
  .warehouse-top {
    padding: 10px 0;
    background-size: 100% 100%;
  }
  .store-view {
    padding-left: 10px;
    .balance-name {
      margin-top: 30px;
      font-size: 12px;
      color: #818086;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      .detail {
        color: #25e2b1;
        cursor: pointer;
      }
    }
    .pay-text {
      margin-top: 15px;
      font-size: 22px;
      // color: #943000;
      display: flex;
      align-items: center;
    }
    .name {
      color: #16172a;
      font-weight: 700;
    }
    .rmb {
      font-size: 12px;
      color: #818086;
      margin-left: 5px;
      display: flex;
      align-items: center;
    }
  }
  .withdrawal-view {
    // text-align: center;
    font-size: 12px;
    color: #c1c1c1;
    margin-top: 10px;
    padding: 0 10px;
    // padding: 0 40px;
    box-sizing: border-box;
    margin: 10px auto;
  }
  .btn-view {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .wallte-btn {
      text-align: center;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      color: #2a2b33;
      font-weight: bold;
      width: 95%;
      background-color: #66eab9;
      border-radius: 10px;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .remark {
    text-align: center;
    font-size: 12px;
    color: #987360;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .warehouse-records {
    margin-top: 25px;
    padding: 20px 10px;
    .name {
      color: #16172a;
      font-size: 20px;
      font-weight: 700;
    }
    .warehouse-list {
      background-size: 100% 100%;
      margin-top: 8px;
      padding: 10px;
    }
    .staking-table {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 35px;
      line-height: 35px;
      border-bottom: 1px solid #f0f0f0;
      .table-title {
        flex: 1;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        color: #16172a;
      }
      .table-title:first-child {
        text-align: left;
      }
      .table-title:last-child {
        text-align: right;
      }
    }
    .value-list {
      display: flex;
      justify-content: space-around;
      align-items: center;
      // height: 35px;
      border-bottom: 1px solid #f0f0f0;
      background-size: 100% 100%;
      .value-item {
        flex: 1;
        text-align: right;

        font-size: 12px;
        font-weight: lighter;
        // border-bottom: 1px solid #f0f0f0;
        // height: 35px;
        line-height: 1.5em;
        padding: 5px 0;
        &.number {
          color: #818086;
          text-align: left;
        }
        &.createTime {
          color: 1px #c1c1c1;
          overflow: hidden;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &.operation {
          color: #818086;
          text-align: right;
          // max-width: 10px;
          word-break: break-all;
        }
        &.status {
          color: #24c294;
        }
      }
    }
  }
}
.icon {
  display: flex;
  width: 100px;
  margin: 0 auto;
  justify-content: space-around;
}
.notice-all {
  text-align: center;
  color: #818086;
  font-size: 12px;
}
.dialog {
  background-color: #fff;
  border-radius: 10px;
  width: 350px;
  // height: 200px;
  background-size: 100% 100%;
  text-align: center;
  font-size: 18px;
  .dialog-title {
    margin-top: 30px;
    color: #231b11;
    .title {
      font-weight: 700;
    }
    .content {
      margin-top: 30px;
      color: #818086;
      font-size: 12px;
      margin-bottom: 30px;
    }
    .botton-group {
      border-top: 1px solid #f0f0f0;
      width: 100%;
      margin: 0 auto;
      margin-top: 40px;
      height: 55px;
      line-height: 55px;
      display: flex;
      font-size: 18px;
      justify-content: space-around;
      .cancel {
        width: 50%;
        color: #c1c1c1;
        border-right: 1px solid #f0f0f0;
      }
      .confirm {
        width: 50%;
        color: #ed8080;
      }
    }
  }
}
</style>
