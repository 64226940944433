import request from '@/utils/request'

// // 获取矿石
// export const queryGoldMining = (params) => {
//   return request('GET', '/app/gold_ore/mineral', params)
// }
// 获取仓库记录
export const queryWarehouseList = (params) => {
  return request('GET', '/app/mining/warehouse_records', params, true, true)
}
// 获取剩详情
export const queryBalanceInfo = (params) => {
  return request('GET', 'app/mining/ore_balance_info', params, true, true)
}
// 获取仓库取出
export const queryWarehouseExtraction = (params) => {
  return request('POST', '/app/mining/warehouse_extraction', params)
}
// 获取矿石
export const queryBalance = (params) => {
  return request('GET', '/app/mining/ore_balance', params, true, true)
}
// 获取lp价值
export const queryLpValue = (params) => {
  return request('GET', '/app/contract/get_lpValue', params)
}
